import React from "react";

import './estilo.css';

import Topo from "../../componentes/Topo";
import Rodape from "../../componentes/Rodapé";



const PaginaPolitica = () => (
    <div>
        <Topo />
        <main>
            <section className="secao-politica limita-largura">
                <p>A Beneficium Investimentos valoriza a privacidade, a segurança e a proteção dos dados pessoais de seus clientes e de todos os titulares que possam ter suas informações processadas em nossos sistemas.</p>

                <p>Nosso compromisso é proporcionar a melhor experiência aos nossos clientes ao acessarem este site. Dessa forma, novos recursos, funcionalidades, produtos ou serviços podem ser periodicamente incorporados. Essas atualizações, aliadas à nossa responsabilidade de resguardar seus dados, podem ocasionar alterações nesta Política de Privacidade sem aviso prévio. Por isso, recomendamos que você consulte este documento regularmente para estar sempre informado.</p>

                <p>Esta Política de Privacidade ("Política") tem como objetivo esclarecer sobre as práticas adotadas na coleta, tratamento, controle e segurança dos seus dados pessoais, garantindo seus direitos como titular, em conformidade com os princípios da finalidade, adequação, necessidade, transparência e livre acesso.</p>

                <p>A presente Política segue as diretrizes da Lei nº 13.709/2018 – Lei Geral de Proteção de Dados ("LGPD").</p>

                <h3>Definição de Dados Pessoais</h3>

                <p>Dados pessoais são quaisquer informações que permitam identificar uma pessoa natural. Já os dados pessoais sensíveis incluem informações sobre origem racial ou étnica, convicções religiosas, opiniões políticas, filiação a sindicatos ou organizações de caráter filosófico, religioso ou político, bem como dados sobre saúde, vida sexual, genética ou biometria, quando vinculados a uma pessoa.</p>

                <p>A LGPD garante o direito ao acesso facilitado a informações sobre o tratamento de seus dados, exigindo que tais informações sejam disponibilizadas de forma clara e acessível.</p>

                <p>Ao mencionarmos nesta Política “usuário”, “você”, “seu(s)” ou “sua(s)”, estamos nos referindo a qualquer titular de dados que utilize o site da Beneficium Investimentos e aplicações associadas.</p>

                <h3>1. Escopo e Consentimento</h3>

                <p>Esta Política descreve a coleta e o uso de dados pessoais ao acessar e utilizar o site da Beneficium Investimentos. Ao aceitar esta Política, você autoriza a Beneficium Investimentos a utilizar seus dados conforme os termos estabelecidos.</p>

                <p>Caso seus dados sejam coletados para finalidades distintas das relacionadas ao uso deste site, como em processos seletivos ou cadastramentos específicos, você será devidamente informado.</p>

                <h3>2. Serviços e Aplicações de Terceiros</h3>

                <p>Esta Política de Privacidade não abrange aplicativos, serviços, produtos, sites ou redes sociais de terceiros que possam ser acessados por meio de links fornecidos em nosso site. O uso desses recursos externos pode envolver a coleta de informações por terceiros, sobre os quais não temos controle. Recomendamos que você revise as políticas de privacidade desses sites antes de compartilhar seus dados.</p>

                <h3>3. Dados Coletados</h3>

                <p>A Beneficium Investimentos pode coletar os seguintes dados pessoais:</p>

                <li>Informações fornecidas por você: Nome, e-mail, telefone/celular, CPF e data de nascimento, quando você navega em nosso site ou entra em contato conosco.</li>

                <li>Dados de dispositivos: Endereço IP, sistema operacional, geolocalização e identificador do dispositivo utilizado para acessar nossos serviços.</li>

                <li>Registros de navegação: Informações sobre interações no site, como páginas visitadas, duração da visita e dados armazenados em cookies.</li>

                <li>Dados estatísticos: Coletamos dados de uso para aprimorar nossos serviços, sempre de forma anonimizada e agregada.</li>

                <h3>4. Uso de Cookies e IP</h3>

                <p>Os cookies são arquivos que armazenam pequenas quantidades de dados em seu dispositivo para melhorar a experiência de navegação. Utilizamos cookies para personalizar conteúdos e analisar o tráfego em nosso site. Caso prefira, você pode configurar seu navegador para bloquear ou excluir cookies, mas isso pode impactar sua experiência em nosso site.</p>

                <p>Também monitoramos endereços IP para fins administrativos, segurança dos sistemas e aprimoramento dos serviços.</p>

                <h3>5. Finalidade do Tratamento de Dados</h3>

                <p>Seus dados pessoais são utilizados para:</p>

                <li>Garantir a melhor experiência ao utilizar nossos serviços;</li>
                <li>Administrar seu acesso a nossos produtos e serviços;</li>
                <li>Verificar sua identidade, quando necessário;</li>
                <li>Melhorar a personalização do site;</li>
                <li>Enviar informações sobre produtos e serviços;</li>
                <li>Responder a solicitações e dúvidas;</li>
                <li>Garantir a segurança de nossos sistemas;</li>
                <li>Cumprir obrigações legais e regulatórias;</li>
                <li>Realizar pesquisas e estatísticas anonimizadas para melhorar nossos serviços.</li>

                <h3>6. Compartilhamento de Dados</h3>

                <p>Seus dados não serão compartilhados com terceiros, salvo nas seguintes situações:</p>

                <li>Quando exigido por lei ou determinação judicial;</li>
                <li>Com consentimento expresso do titular;</li>
                <li>Para cumprimento de obrigações contratuais;</li>
                <li>Com parceiros e prestadores de serviços essenciais à nossa operação, sempre garantindo a proteção dos dados.</li>

                <p>Não vendemos, alugamos ou utilizamos os dados pessoais de nossos usuários como moeda de troca em operações comerciais.</p>

                <h3>7. Segurança e Proteção de Dados</h3>

                <p>Adotamos medidas de segurança para proteger seus dados pessoais, como criptografia, controles de acesso e auditorias internas. Embora tomemos precauções, nenhum sistema é totalmente imune a invasões. Caso identifiquemos qualquer incidente de segurança, notificaremos os usuários conforme exigido pela legislação.</p>

                <h3>8. Privacidade de Crianças</h3>
                <p>Nosso site não se destina a menores de idade e não coletamos intencionalmente dados de crianças sem o consentimento de seus responsáveis.</p>

                <h3>9. Comunicados e E-mails</h3>
                <p>Utilizaremos seu e-mail apenas para os fins informados no momento do fornecimento, como responder às suas solicitações ou enviar comunicações institucionais. Caso tenha autorizado, poderemos enviar conteúdos promocionais, sendo possível cancelar esse envio a qualquer momento.</p>

                <h3>10. Seus Direitos como Titular</h3>
                <p>Você pode, a qualquer momento, solicitar:</p>
                <li>A confirmação sobre o tratamento de seus dados;</li>
                <li>O acesso aos seus dados armazenados;</li>
                <li>A correção de informações incorretas ou desatualizadas;</li>
                <li>A exclusão de dados tratados sem base legal;</li>
                <li>A portabilidade dos dados, conforme regulamentação da ANPD;</li>
                <li>A revogação de consentimento para tratamento de dados.</li>

                <p>Para exercer seus direitos, entre em contato pelo e-mail indicado em nosso site.</p>

                <h3>11. Alterações na Política de Privacidade</h3>
                <p>Podemos atualizar esta Política periodicamente. Recomendamos que você revise este documento regularmente para estar ciente de possíveis mudanças.</p>
                <p>Caso tenha dúvidas sobre esta Política de Privacidade, entre em contato com a Beneficium Investimentos através dos canais disponíveis em nosso site.</p>
            </section>
        </main>
        <Rodape />
    </div>
);

export default PaginaPolitica;