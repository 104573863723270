import React from "react";

import './estilo.css';

import {Link} from 'react-router-dom';

import Instagram from '../../assets/logo-insta.svg';
import XP from '../../assets/logo-xp-branca.webp';
import Beneficium from '../../assets/logo-beneficium.png';
import Linkedin from '../../assets/logo-link.svg'


function Rodape () {
    return(
        <footer>
            <div className="rodape-pagina limita-largura">

                <div className="endereco-contato-rodape">
                    <div className="endereco-rodape">
                        <h3>ENDEREÇO</h3>
                        <p>Av. Dom Luis, 300</p>
                        <p>60160-230, Fortaleza-CE</p>
                    </div>
                    <div className="contato-rodape">
                        <h3>CONTATO</h3>
                        <p>atendimento@beneficiumai.com.br</p>
                        <p>(85) 3512-0757</p>
                    </div>
                </div>

                <div className="div-politica">
                    <Link className="politica" to='/politica-de-privacidade'>POLÍTICA DE PRIVACIDADE</Link>
                </div>

                <div className="redes-sociais-rodape">
                    <a href="https://instagram.com/beneficiumai"><img alt="logo instagram" src={Instagram}></img></a>
                    <a href="https://www.linkedin.com/company/beneficium-assessoria-de-investimentos/about/?viewAsMember=true"><img alt="logo instagram" src={Linkedin}></img></a>
                </div>

                <div className="disclaimer-rodape">
                    <p>A BENEFICIUM ASSESSORIA DE INVESTIMENTOS, inscrita sob o CNPJ: 53.408.127/0001-00 é uma empresa de Assessoria de Investimento devidamente registrada na Comissão de Valores
Mobiliários na forma da Resolução CVM 178/23 (“Sociedade”), que mantém contrato de distribuição de
produtos financeiros com a XP Investimentos Corretora de Câmbio, Títulos e Valores Mobiliários S.A. (“XP”)
e pode, por conta e ordem dos seus clientes, operar no mercado de capitais segundo a legislação vigente.
Na forma da legislação da CVM, o Assessor de Investimento não pode administrar ou gerir o patrimônio de investidores. O investimento em ações é um investimento de risco e rentabilidade passada não é garantia
de rentabilidade futura. Na realização de operações com derivativos existe a possibilidade de perdas
superiores aos valores investidos, podendo resultar em significativas perdas patrimoniais A Sociedade
poderá exercer atividades complementares relacionadas aos mercados financeiro, securitário, de previdência
e capitalização, desde que não conflitem com a atividade de assessoria de investimentos, podendo ser
realizada por meio da pessoa jurídica acima descrita ou por meio de pessoa jurídica terceira. Todas as
atividades são prestadas mantendo a devida segregação e em cumprimento ao quanto previsto nas regras
da CVM ou de outros órgãos reguladores e autorreguladores. Para informações e dúvidas sobre produtos,
contate seu assessor de investimentos. Para reclamações, contate a Ouvidoria da XP pelo telefone 0800 722
3730.</p>
                </div>

                <div className="logos-rodape">
                    <img alt="Logo Beneficium" className="logo-bene" src={Beneficium}></img>
                    <img alt="Logo XP" className="logo-xp" src={XP}></img>
                </div>
            </div>
            <p className="copy">&copy; BENEFICIUM ASSESSORIA DE INVESTIMENTOS LTDA, 2024.</p>
        </footer>
    );
}

export default Rodape;