import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import PaginaHome from '../paginas/PaginaHome';
import PaginaContato from '../paginas/PaginaContato';
import PaginaQuem from '../paginas/PaginaQuem';
import PaginaAbrirConta from '../paginas/PaginaAbrirConta';
import PaginaPolitica from '../paginas/PaginaPolitica';

const Rotas = () => (
  <BrowserRouter>
    <Routes>
      <Route path='/' element={<PaginaHome/>}/>
      <Route path='/contato' element={<PaginaContato/>}/>
      <Route path='/sobre' element={<PaginaQuem/>}/>
      <Route path='/abra-conta' element={<PaginaAbrirConta/>}/>
      <Route path='/politica-de-privacidade' element={<PaginaPolitica/>}/>
    </Routes>
  </BrowserRouter>
);

export default Rotas;